<template>
	<div class="list">

	</div>
</template>
<script>
import {
	mapActions,
	mapState
} from 'vuex'
import help from '../utils/Help'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'

import {
	Toast
} from 'vant';
import Vue from 'vue';

import {
	Dialog
} from 'vant';


export default {
	name: 'Home',
	components: {
		[Dialog.Component.name]: Dialog.Component,
	},
	data: function () {
		return {
			currentUrl: ''
		}
	},
	props: {

	},
	beforeMount() {

	},
	async mounted() {
		// pay?biz=电影
		// noticePhone=13588232814
		// bizNo=1313458695606915072
		// addtime=2024-12-03%2010:55:56
		// deliveryPrice=0
		// serviceCharge=0
		// totalGoodsAmount=80.1
		// openId=cb908e0414fdcec4
		// sign=ead76fc0e3b006912bc264bd555279ba

		const biz = urlParams.get('biz')// 业务标识，有以下几种:, 肯德基, 特惠充值, 电影, 卡券, 麦当劳, 星巴克, 必胜客, 奈雪, 库迪, 瑞幸
		const noticePhone = urlParams.get('noticePhone')//下单手机号
		const bizNo = urlParams.get('bizNo')//业务单号，唯一
		const addtime = urlParams.get('addtime')//下单时间，格式yyyy-MM-dd HH:mm:ss
		const deliveryPrice = urlParams.get('deliveryPrice')//配送费
		const serviceCharge = urlParams.get('serviceCharge')//平台服务费
		const totalGoodsAmount = urlParams.get('totalGoodsAmount')//商品金额
		const openId = urlParams.get('openId')//用户唯一标识，即登录对接时传入的platformuid字段
		const payback = urlParams.get('payback') //已经支付完或者取消支付的回调

		const UA = navigator.userAgent.toLowerCase()
		const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
		const inMini = window.__wxjs_environment === 'miniprogram';
		if (payback) {
			this.waitResult(biz, bizNo, openId)
		} else {
			Toast.loading({
				message: '请稍候...',
				forbidClick: true,
				duration: 0
			});

			// 如果需要跳转，就直接跳转到其他页面去了
			const furl = await this.forward_url(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId);
			if (furl) {
				return
			}

			if (inWechat) {
				if (inMini) {
					this.wxminiPay(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId);
				} else {
					this.wxPay(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId)
				}
			} else {
				this.wxh5Pay(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId)
			}
		}

	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post', 'syncpost']),
		// 查看是否需要跳转
		async forward_url(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId) {

			try {
				let furl = await this.syncpost({
					url: "yizhifu/forward_url",
					data: {
						'biz': biz,
						'noticePhone': noticePhone,
						'bizNo': bizNo,
						'addtime': addtime,
						'deliveryPrice': deliveryPrice,
						'serviceCharge': serviceCharge,
						'totalGoodsAmount': totalGoodsAmount,
						'openId': openId,
					}
				});
				window.location.href = furl
				return true
			} catch (error) {
				console.info(error)
			}
			return false

		},
		// 微信内支付
		wxPay(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId) {
		},
		// 微信小程序内支付
		wxminiPay(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId) {
			this.post({
				url: "yizhifu/xiadan_mini",
				data: {
					'biz': biz,
					'noticePhone': noticePhone,
					'bizNo': bizNo,
					'addtime': addtime,
					'deliveryPrice': deliveryPrice,
					'serviceCharge': serviceCharge,
					'totalGoodsAmount': totalGoodsAmount,
					'openId': openId,
				},
				success: (data) => {
					Toast.clear()
					data.pay_info.biz = biz

					const urlParams = new URLSearchParams(data.pay_info);
					const searchParams = urlParams.toString();
					console.info(searchParams)
					wx.miniProgram.navigateTo({
						url: '/pages/callpayment?' + searchParams
					})

					this.waitResult(biz, bizNo, openId)
				},
				error: (code, err) => {
					Toast.clear()
					Toast.fail(err);
				}
			});
		},

		// 微信外 h5/app 支付
		wxh5Pay(biz, noticePhone, bizNo, addtime, deliveryPrice, serviceCharge, totalGoodsAmount, openId) {
			var url = "yizhifu/xiadan_h5";
			var self = this;
			this.post({
				url: url,
				data: {
					'biz': biz,
					'noticePhone': noticePhone,
					'bizNo': bizNo,
					'addtime': addtime,
					'deliveryPrice': deliveryPrice,
					'serviceCharge': serviceCharge,
					'totalGoodsAmount': totalGoodsAmount,
					'openId': openId,
				},
				success: (data) => {
					// const return_url = encodeURIComponent(window.location.href + "&payback=1")
					window.location.href = data.pay_info.h5_url
					//  + '&redirect_url=' + return_url;
					Toast.clear()
					this.waitResult(biz, bizNo, openId);
				},
				error: (code, err) => {
					Toast.clear()
					Toast.fail(err);
				}
			});
		},
		// 弹出等待确认框
		async waitResult(biz, bizNo, openId) {

			let backurl = await this.syncpost({
				url: "yizhifu/order_url",
				data: {
					"biz": biz,
					"bizNo": bizNo,
					"openId": openId
				}
			});

			window.location.href = backurl
			return

			Dialog.confirm({
				title: '提示',
				message: '已经支付完成？',
				cancelButtonText: '放弃支付',
				confirmButtonText: '支付完成'
			}).then(() => {
				// on cancel
				Toast.loading({
					message: '处理中...',
					forbidClick: true,
					duration: 0
				});
				this.post({
					url: "yizhifu/order_status",
					data: {
						'bizNo': bizNo,
					},
					success: (data) => {
						Toast.clear()
						if (data != 2) {
							Toast.fail('支付未完成');
							return
						}
						window.location.href = backurl
					},
					error: (code, err) => {
						Toast.clear()
						Toast.fail(err);
					}
				});
			}).catch(() => {
				window.location.href = backurl
			});
		},
	}
}
</script>
<style scoped></style>
