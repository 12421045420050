<template>
	<div class="list">
		{{ currentUrl }}
	</div>
</template>
<script>
import {
	mapActions,
	mapState
} from 'vuex'
import help from '../utils/Help'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'

import {
	Toast
} from 'vant';
import Vue from 'vue';

import {
	Dialog
} from 'vant';


export default {
	name: 'Home',
	components: {
		[Dialog.Component.name]: Dialog.Component,
	},
	data: function () {
		return {
			currentUrl: ''
		}
	},
	props: {

	},
	beforeMount() {

	},
	mounted() {

		let type = urlParams.get('type') ? urlParams.get('type') : 'home'
		let wxOpenId = urlParams.get('openId') ? urlParams.get('openId') : ''

		// 仅在页面第一次加载时调用
		BestpayHtml5.config();
		const clientId = "86003000392";
		const merchantCode = "3178038338355766";
		/**
		 * 交互使用说明请参考《翼支付jssdk开放文档》
		 * scope: [String], 默认为空, 可选填 user_info, balance, mobile
		 * clientId: [String], 必填, 第三方应用编号
		 * redirectUrl: [String], 必填, 回调地址, 与商户提供的“授权回调URL地址”保持一致
		 * merchantCode: [String], 必填, 商户号, 与商户提供的“商户代码”一致（即商服平台上的商户号）
		 * state: [String], 必填, 第三方自定义参数，长度不超过100字符
		 */
		BestpayHtml5.User.userAuth({
			scope: "mobile,user_info",
			clientId: clientId,
			redirectUrl: "https://api.zhsyh.cn/callback/yizhifu",
			merchantCode: merchantCode,
			state: "123"
		}, (res) => {

			this.post({
				url: "yizhifu/authCode2url",
				data: {
					'grantType': res.grantType,
					'authCode': res.code,
					'clientId': clientId,
					'merchantCode': merchantCode,
					'type': type,
					'wxOpenId': wxOpenId
				},
				success: (data) => {
					window.location.href = data
				},
				error: (code, err) => {

				}
			});

			console.info(res)
		}, (res) => {
			console.info(res)
		}, (res) => {
			console.info(res)
		})
	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post']),
		dialogclose() {

		},
	}
}
</script>
<style scoped></style>
